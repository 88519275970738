import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'
import { Translation } from 'react-i18next'
import { withTheme } from 'styled-components'
import { Box, Flex, Heading, Card } from 'rebass/styled-components'
import slugify from 'slugify'
import BackgroundImage from 'gatsby-background-image'

import Layout from '../components/Layout'
import { LightSection } from '../components/Section'
import Container from '../components/Container'
import SectionTitle from '../components/SectionTitle'
import { withI18next, Link } from 'gatsby-plugin-i18n'

const BlogPostsPage = ({ data, pageContext }) => {
  const { heroImage } = data
  const { language } = pageContext
  const { edges: blogPosts } = data.blogPosts

  return (
    <Translation>
      {(t) => (
        <Layout language={language}>
          <Box as="header" sx={{ height: ['10rem', '12rem', '20rem'] }}>
            <BackgroundImage
              fluid={heroImage.childImageSharp.fluid}
              style={{ backgroundPosition: '50% 80%', height: '100%' }}
            />
          </Box>

          <main>
            <LightSection>
              <Container>
                <SectionTitle as="h2">
                  {t('blogPosts:title', {
                    defaultValue: 'Blog',
                  })}
                </SectionTitle>

                <>
                  {blogPosts &&
                    blogPosts.map(({ node: blogPost }) => {
                      const { id, title, image, slug } = blogPost
                      const blogPostSlug =
                        slug || slugify(title, { lower: true })
                      const link = `/blog/${blogPostSlug}`

                      return (
                        <Box key={id} sx={{ mb: 4 }}>
                          <Link to={link} style={{ textDecoration: 'none' }}>
                            <Card sx={{ p: 0 }}>
                              <Flex
                                sx={{
                                  flexDirection: ['column', 'column', 'column'],
                                }}
                              >
                                <Box
                                  sx={{
                                    width: ['100%', '100%', '100%'],
                                    lineHeight: 0,
                                    order: [0, 0, 0],
                                  }}
                                >
                                  {image && image.local && (
                                    <Link to={link}>
                                      <Img
                                        fluid={
                                          image.local.childImageSharp.fluid
                                        }
                                        alt={title}
                                        style={{
                                          maxWidth: '100%',
                                        }}
                                      />
                                    </Link>
                                  )}
                                </Box>

                                <Flex
                                  sx={{
                                    flexDirection: 'column',
                                    width: ['100%', '100%', '100%'],
                                    order: [1, 1, 1],
                                    p: 4,
                                  }}
                                >
                                  <Heading as="h2">
                                    <Link
                                      to={link}
                                      style={{ textDecoration: 'none' }}
                                    >
                                      {title}
                                    </Link>
                                  </Heading>

                                  <Link
                                    to={link}
                                    style={{
                                      display: 'block',
                                      marginTop: 'auto',
                                    }}
                                  >
                                    {t('blogPosts:continue-reading', {
                                      defaultValue: 'Continue reading',
                                    })}
                                  </Link>
                                </Flex>
                              </Flex>
                            </Card>
                          </Link>
                        </Box>
                      )
                    })}
                </>
              </Container>
            </LightSection>
          </main>
        </Layout>
      )}
    </Translation>
  )
}

BlogPostsPage.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
}

export default withTheme(withI18next()(BlogPostsPage))

export const pageQuery = graphql`
  query BlogPostsPageTemplate($language: String!) {
    locales: allLocale(
      filter: {
        language: { eq: $language }
        ns: { in: ["messages", "blogPosts"] }
      }
    ) {
      ...LocalLocaleFragment
    }
    heroImage: file(relativePath: { eq: "home-jumbotron.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    blogPosts: allBlogPosts(
      filter: { language: { eq: $language } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          id
          title
          slug
          image {
            local {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`
